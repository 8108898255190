




































import { Component, Vue } from 'vue-property-decorator';
import { dispatchCreateDocumentoCategoria } from '@/store/admin/actions';

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName: string = '';
  public categoriaAlias: string = '';

  public reset() {
    this.fullName = '';
    this.categoriaAlias = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      let categoria = '';
      let alias = '';
      if (this.fullName) {
        categoria = this.fullName;
        alias = this.categoriaAlias;
      }
      if (this.categoriaAlias) {
        alias = this.categoriaAlias;
      }
      await dispatchCreateDocumentoCategoria(this.$store, { categoria, alias });
      this.$router.push('/main/admin/documentos/categorias');
    }
  }
}
